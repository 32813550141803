.recharts-cartesian-axis-tick {
  text {
    tspan {
      color: $color-grey-8;
      font-family: Aller;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 7px;
      text-align: right;
    }
  }
}
