@import 'styles/settings';

.ReactModal__Overlay {
  z-index: 100;
  background: rgba($color-grey-8, 0.7);
  transform: scale(1.15);
  transition:
    transform 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946),
    opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  transition:
    transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946),
    opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.ReactModal__Overlay--before-close {
  transform: scale(1.15);
}
