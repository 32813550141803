@import 'styles/settings';

.c-footer {
  width: 100%;
  color: $white;
  background-color: $color-grey-8;
  padding: 0 0 $padding;
  font-family: $font-family-1;
  position: relative;

  @media print {
    background-color: $white;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 34px;
  }

  .footer-header {
    display: flex;
    align-items: center;
    padding: $padding 0;
    justify-content: space-between;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);

    .footer-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;

      *:not(:last-child) {
        margin-right: 20px;

        @media (max-width: map-get($breakpoints, md)) {
          margin-right: 0;
          margin-bottom: 20px;
        }

        @media (max-width: map-get($breakpoints, sm)) {
          margin-bottom: 0;
        }
      }

      @media (max-width: map-get($breakpoints, md)) {
        flex-direction: column;
        margin-top: 20px;
      }
    }

    @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column;
    }

    @media print {
      display: none;
    }
  }

  .footer-navigation {
    width: 100%;
    display: flex;
    overflow-y: auto;

    @media print {
      display: none;
    }
  }

  aside {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      height: auto;
      width: 200px;
      padding: 10px;
    }

    p {
      color: $color-3;
    }
  }
}
